const ExportedPage = () => import('./pages/ExportedPage.vue');
const EditItemsPage = () => import('./pages/EditItemsPage.vue');
const ShareExportItemsPage = () => import('./pages/ShareExportItemsPage.vue');
const CreateBundlePage = () => import('./pages/CreateBundlePage.vue');
const BatchInstallsPage = () => import('./pages/BatchInstallsPage.vue');
const InstallPublicPage = () => import('./pages/consumer/InstallPublicPage.vue');
const InstallItemPage = () => import('./pages/consumer/InstallItemPage.vue');
const InstallItemOnAppPage = () => import('./pages/consumer/InstallItemOnAppPage.vue');
const InstallItemChooseAppPage = () => import('./pages/consumer/InstallItemChooseAppPage.vue');

const routes = [
    {
        path: '/shared',
        name: 'bundles',
        redirect: '/shared/bundles',
    },
    {
        path: '/shared/:exportType',
        name: 'shared.list',
        component: ExportedPage,
        meta: {
            showIcon: true,
            title: 'app.title',
        },
    },
    {
        path: '/edit/:resourceType/:sharedResourceId/:stage',
        name: 'share.item',
        component: EditItemsPage,
        meta: {
            title: 'share.pageTitle',
        },
    },
    {
        path: '/create/bundle',
        name: 'create.bundle',
        component: CreateBundlePage,
    },
    {
        path: '/create/export',
        name: 'create.export',
        component: ShareExportItemsPage,
        meta: {
            title: 'export.pageTitle',
        },
    },
    {
        path: '/go/:linkId',
        name: 'install.item.public',
        component: InstallPublicPage,
        meta: {
            title: 'share.pageTitle',
            isPublic: true,
        },
    },
    {
        path: '/go-to-install/:linkId',
        name: 'install.item.goToInstall',
        component: InstallItemPage,
        meta: {
            title: 'share.pageTitle',
            skipAuthorization: true,
        },
    },
    {
        path: '/install-request/:appId/:requestId',
        name: 'install.item.app.request',
        component: InstallItemOnAppPage,
        meta: {
            title: 'share.pageTitle',
            skipAuthorization: true,
        },
    },
    {
        path: '/install/:appId/:linkId',
        name: 'install.item.app',
        component: InstallItemOnAppPage,
        meta: {
            title: 'share.pageTitle',
            skipAuthorization: true,
        },
    },
    {
        path: '/install/:linkId',
        name: 'install.item',
        component: InstallItemPage,
        meta: {
            title: 'share.pageTitle',
            isPublic: true,
        },
    },
    {
        path: '/install-on-app/:linkId',
        name: 'install.item.choose.app',
        component: InstallItemChooseAppPage,
        meta: {
            title: 'share.pageTitle',
            skipAuthorization: true,
        },
    },
    {
        path: '/batch-install/:batchRequestId',
        name: 'batch.install.report.request',
        component: BatchInstallsPage,
        meta: {
            title: 'share.pageTitle',
        },
    },
    {
        path: '/batch-installs',
        name: 'batch.install.report',
        component: BatchInstallsPage,
        meta: {
            title: 'share.pageTitle',
        },
    },
];

export default routes;
